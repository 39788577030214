import { styled } from "@stitches/react";
import React, { FC } from "react";
import { Wrapper, Header, SubHeader, Text, List, Item } from "./global";

type LocationProps = {
  id: string;
};

const Location: FC<LocationProps> = ({ id }) => {
  return (
    <Wrapper id={id}>
      <Header>Getting there</Header>
      <SubHeader>Flights</SubHeader>
      <Text>
        The closest airports for guests traveling from out of state are:
      </Text>
      <List>
        <Item>Jacksonville International Airport</Item>
        <Item>Orlando International Airport</Item>
      </List>

      <SubHeader>Accommodations</SubHeader>
      <Text>
        We've reserved a block of hotel rooms at the{" "}
        <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1727198319364&key=GRP&guestreslink2=true">
          World Golf Renaissance
        </a>
        . You can book through{" "}
        <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1727198319364&key=GRP&guestreslink2=true">
          this link
        </a>
        . This is where the bride and groom will be staying.
      </Text>

      <Text>
        You can also reserve rooms at the{" "}
        <a href="https://www.holidayinnstaugustine.com/">
          Holiday Inn St. Augustine
        </a>{" "}
        or{" "}
        <a href="https://www.ihg.com/holidayinnexpress/hotels/us/en/fleming-island/jaxfi/hoteldetail">
          The Holiday Inn Express & Suites Fleming Island
        </a>
        . AirB&B rentals are available but limited in the area.
      </Text>

      <SubHeader>Transportation</SubHeader>

      <Text>
        We <b>strongly encourage</b> guests to rent a vehicle.
      </Text>

      <Text>
        The area around the wedding venue and preferred hotels is rural, and
        rideshare services may be limited.
      </Text>

      <Text>
        On the day of the wedding, we will provide a bus to shuttle guests
        between World Golf Renaissance and The Clay Theatre before the ceremony
        (4 p.m.) and after the reception (1 a.m.).
      </Text>
      <Text>
        If you decide to drive,{" "}
        <a href="https://www.google.com/maps/dir/World+Golf+Village+Renaissance+St.+Augustine+Resort,+500+S+Legacy+Trail,+St.+Augustine,+FL+32092/Clay+Theatre,+Walnut+Street,+Green+Cove+Springs,+FL/@29.9657097,-81.6315709,11.75z/data=!4m14!4m13!1m5!1m1!1s0x88e42c63b8cbf159:0xbd0a59d33ec97564!2m2!1d-81.4682239!2d29.9903954!1m5!1m1!1s0x88e5d0b21e17349d:0x42ea6ff9ea430616!2m2!1d-81.6791705!2d29.9937623!3e0?entry=ttu&g_ep=EgoyMDI0MTExOC4wIKXMDSoASAFQAw%3D%3D">
          here are directions
        </a>{" "}
        from World Golf Renaissance to Clay Theatre. The venue provides plenty
        of parking.
      </Text>

      <SubHeader>Planning your visit</SubHeader>

      <Text>
        <a href="https://suitelifesanburg.com/st-augustine-itinerary/">
          Guide to exploring St. Augustine
        </a>
        .
      </Text>
      <Text>
        Also check out some{" "}
        <a href="https://maps.app.goo.gl/PzMRzsKzVDrVQ6J17">
          local recommendations
        </a>
        !
      </Text>

      <Text>
        Our favorite places in Green Cove Springs:
        <ul>
          <li>Sweet Sensations Cafe</li>
          <li>Spring Park Coffee</li>
          <li>La Casita Mexican Restauraunt</li>
          <li>Spring Park & Public Pier</li>
        </ul>
      </Text>
    </Wrapper>
  );
};

export default Location;
