import React from "react";
import { styled } from "./styles";

const NavContainer = styled("div", {
  position: "sticky",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  zIndex: "1",
  fontFamily: "$sans",
  margin: "2em auto",
  "@media(max-width: 600px)": {
    flexWrap: "wrap"
  }
});

const NavLink = styled("a", {
  border: "1px solid black",
  borderRadius: "$025",
  width: "100%",
  textAlign: "center",
  padding: "0.5em",
  margin: "0.25em",
  textDecoration: "none",
  "&:hover": {
    background: "#ccc",
    transition: "background 0.25s ease"
  }
});

const navLinks = [
  { title: "Travel", id: "location" },
  { title: "Schedule", id: "schedule" },
  { title: "RSVP", id: "rsvp", url: "https://forms.gle/HD1PMdh55QLTrxbB9" },
  {
    title: "Registry",
    id: "registry",
    url: "https://honeyfund.com/site/Alcantara2025"
  }
];

const NavBar = () => {
  return (
    <NavContainer>
      {navLinks.map(function (link: any, i: number) {
        const { title, id, url } = link;

        const anchorToUse = url ? url : `#${id}`;

        return (
          <NavLink key={i} href={anchorToUse}>
            {title}
          </NavLink>
        );
      })}
    </NavContainer>
  );
};

export default NavBar;
